import React from "react";

import StickyBox from "react-sticky-box";

import NavigationBar from "./components/common/NavigationBar";
import Feed from "./components/common/Feed";
import CTASection from "./components/common/CTASection";
import Footer from "./components/common/Footer";

import Backend from "../utils/Backend";
import General from "../utils/General";
import Banner from "./components/common/Banner";
import CompanyBanner from "./components/channel/Banner";
import CompanyCard from "./components/channel/CompanyCard";
import PreLoad from "./components/common/PreLoad";
import PartnerBranding from "./components/common/PartnerBranding";
import ScriptCache from "../utils/ScriptCache";
import MetaTags from "../utils/MetaTags";
import RobinScript from "../utils/RobinScript";

export default class Channel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      channel: props.channel,
      jobs: props.jobs,
    }
  }

  componentDidMount() {
    this._loadJs()
    this._logTraffic()
    if(this.state.partner.settings.robin){
      this._loadRobin()
    }
  }

  _loadJs(){
    ScriptCache.loadDefaults()
  }

  _loadRobin(){
    RobinScript.load(this.state.partner.slug)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this._logTraffic()
  }

  _logTraffic(){
    let data = General.getTrafficData(this.state.partner)
    data["channel"] = this.state.channel.id
    Backend.logTraffic(data)
  }

  _renderChannel(){
    let{
      jobs,
      channel,
      partner,
    } = this.state

    return (
      <>
        <Banner
          image={channel.banner}
          className={"cover height-50"}
        >
          <CompanyBanner
            channel={channel}
            partner={partner}
          />
        </Banner>
        <section className="maincolumns job sticky-sidebars">
          <div className="container">
            <div className="row">
              <Feed
                partner={partner.slug}
                channel={channel.slug}
                className={"col-lg-9 middlecol mb-5 pb-3"}
                jobs={jobs.results}
                hasMore={jobs.has_more}
              />
              <CompanyCard
                channel={channel}
                partner={partner}
              />
            </div>
          </div>
        </section>
      </>
    )
  }

  render() {
    let{
      channel,
      partner,
    } = this.state

    if(!channel) return <PreLoad/>

    let shareTitle = `${channel.name} Jobs & Careers | ${partner.name}`;
    let description = `Find the Latest jobs at ${partner.name} today. 
                       Connect with the company and learn about their culture. You can also follow this 
                       channel to make sure you don't miss any career opportunity at ${channel.name} that will come 
                       available in the future. Find your dream job now.`;
    let url = window.location.href;
    let shareImage = partner.logo;

    let canonicalLink = `${window.General.JobbioSite}/${channel.slug}`

    return (
      <>
        { MetaTags.render(shareTitle, description, null, url, shareImage, canonicalLink) }
        <NavigationBar
          partner={partner}
          opacity={' bar--absolute bar--transparent '}
        />
        {
          this._renderChannel()
        }
        <Footer
          partner={partner}
        />
      </>
    )
  }
};
