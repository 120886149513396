import React from "react";

import NavigationBar from "./components/common/NavigationBar";
import Footer from "./components/common/Footer";

import Banner from "./components/search/Banner";
import General from "../utils/General";
import JobResults from "./components/search/JobResults";
import Backend from "../utils/Backend";
import PreLoad from "./components/common/PreLoad";
import PartnerBranding from "./components/common/PartnerBranding";
import ScriptCache from "../utils/ScriptCache";
import MetaTags from "../utils/MetaTags";
import RobinScript from "../utils/RobinScript";

export default class SearchJobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      searchTerm: General.getUrlParameter("searchTerm"),
      location: General.getUrlParameter("location"),
      homepage: General.getUrlParameter("homepage"),
      pageCount: General.getUrlParameter("page"),
    }
  }

  componentDidMount() {
    this._loadJs()
    if(this.state.partner.settings.robin){
      this._loadRobin()
    }
    if(this.state.searchTerm) {
      Backend.registerSearch(this.state.searchTerm, this.state.partner)
    }
    let data = General.getTrafficData(this.state.partner)
    data["search_jobs_page"] = true
    Backend.logTraffic(data)
  }

  _loadJs(){
    ScriptCache.loadDefaults()
  }

  _loadRobin(){
    RobinScript.load(this.state.partner.slug)
  }

  render() {
    let {
      searchTerm,
      location,
      partner,
      filters,
      pageCount,
    } = this.state

    let shareTitle = `Search Jobs And Career Opportunities - Find Your Dream Job Today! | ${partner.name}`;
    let description = `Search jobs on ${partner.name} that are looking for great talent like you. On our job search 
                       platform you'll be able to search and apply to jobs. Begin your search and start applying today!`;
    let url = window.location.href;
    let shareImage = partner.logo;

    return (
      <div className="page-search-job pages search-jobs ui-jobs">

        { MetaTags.render(shareTitle, description, null, url, shareImage) }
        <NavigationBar
          opacity={'bar--absolute '}
          partner={partner}
          headerFixed='true'
        />
        <div className={'main-container full-page '}>
          <Banner
            searchTerm={searchTerm}
            location={location}
            filters={filters}
            page={"jobs"}
            size={"height-auto"}
          />
          <section className="search-tabs">
            <div className="container">
              <JobResults
                searchTerm={searchTerm}
                location={location}
                filters={filters}
                pageCount={pageCount}
                partner={partner}
              />
            </div>
          </section>
          <Footer
            partner={partner}
          />
        </div>

      </div>
    )
  }
}
